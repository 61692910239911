<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Logo',

  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      name: 'vitrine'
    }
  },

  computed: {
    ...mapGetters([ 'clientConfig' ])
  }
}
</script>

<template>
  <div class="logo">
    <img
      v-if="clientConfig && clientConfig.clientAlias"
      :src="`/assets/images/themes/${clientConfig.clientAlias}/${dark ? 'logo-dark.png' : 'logo-light.png'}`"
      class="logo-image"
    >
  </div>
</template>

<style src="@/assets/styles/themes/default/logo.css"></style>
